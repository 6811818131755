import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { refundLogo, noRefundLogo } from "components/SharedImages"
import { CardTitle, Col, Table } from "reactstrap"
import { map } from "lodash"
import SellcoItems from "./SellcoItems"
const SellingPlatform = props => {
  const { email } = props
  const [parsedItem, setParsedItem] = useState({})
  const [remarks, setRemarks] = useState()
  const [refund, setRefund] = useState()

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  // let refundLogo =
  //   "https://res.cloudinary.com/developerqa/image/upload/v1736774509/merchant/45225461_tmyasv.jpg"
  // let noRefundLogo =
  //   "https://res.cloudinary.com/developerqa/image/upload/v1736774514/merchant/48694533_fx194s.jpg"

  useEffect(() => {
    var parsedItem = JSON.parse(
      props.purchasedItems ? props.purchasedItems : "null"
    )
    setParsedItem(parsedItem)
  }, [])
  useEffect(() => {
    //console.log(parsedItem)
    if (parsedItem) {
      setRemarks(parsedItem.RM_PnrRemarks)
    }
  }, [parsedItem])
  useEffect(() => {
    //console.log(remarks)
    remarks?.map(rm => {
      //console.log(rm?.REMARK_TEXT)
      if (rm?.REMARK_TEXT?.includes("*FARETYPE")) {
        //console.log("FARETYPE")
        setRefund(rm?.REMARK_TEXT.split(":")[1])
      }
    })
  }, [remarks])
  useEffect(() => {
    //console.log(refund)
  }, [refund])

  return (
    <>
      {parsedItem?.U_UnticketedAirSegment && (
        <Col lg={12}>
          <CardTitle style={{ background: "#EAEFF5", padding: "1px" }}>
            {props.t("FLIGHT DETAILS")}
          </CardTitle>

          <Table responsive size="sm" style={{ textAlign: "left" }}>
            <tbody>
              <tr>
                <th scope="col">
                  <small>
                    <b>{props.t("FLIGHT NO")}</b>
                  </small>
                </th>
                <th scope="col">
                  <small>
                    <b>{props.t("DEPARTURE")}</b>
                  </small>
                </th>
                <th scope="col">
                  <small>
                    <b>{props.t("DEPARTURE DATE-TIME")}</b>
                  </small>
                </th>
                <th scope="col">
                  <small>
                    <b>{props.t("ARRIVAL")}</b>
                  </small>
                </th>
                <th scope="col">
                  <small>
                    <b>{props.t("ARRIVAL DATE-TIME")}</b>
                  </small>
                </th>
                <th scope="col">
                  <small>
                    <b>{props.t("FLIGHT DURATION")}</b>
                  </small>
                </th>
                <th scope="col">
                  <small>
                    <b>{props.t("NO OF STOPS")}</b>
                  </small>
                </th>
              </tr>

              {map(parsedItem?.U_UnticketedAirSegment, (item, index) => (
                <tr key={index}>
                  <td>
                    <small>
                      {" "}
                      {item.AIRLINE_CODE} - {item.FLIGHT_NUMBER}
                    </small>
                  </td>
                  <td>
                    <small>
                      {" "}
                      {item.ORIGIN_CITY_AIRPORT_NAME} -{" "}
                      {item.ORIGIN_COUNTRY_CODE}
                    </small>
                  </td>
                  <td>
                    <small>
                      {item.DEPARTURE_DATE} - {item.DEPARTURE_TIME}
                    </small>
                  </td>
                  <td>
                    <small>
                      {item.DESTINATION_CITY_AIRPORT_NAME}-{" "}
                      {item.DESTINATION_COUNTRY_CODE}
                    </small>
                  </td>
                  <td>
                    <small>
                      {item.ARRIVAL_DATE} - {item.ARRIVAL_TIME}
                    </small>
                  </td>
                  <td>
                    <small>{item.FLIGHT_DURATION_TIME}</small>
                  </td>
                  <td>
                    <small> {item.NUMBER_OF_STOPS}</small>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      )}

      {parsedItem?.I_Name && (
        <Col lg={12}>
          {isMobile ? (
            <CardTitle style={{ background: "#EAEFF5", padding: "1px" }}>
              {props.t("PASSENGER DETAILS")}
              <small>
                <br />
                PNR : <b>{parsedItem.AMD_AirRecordHeader.SYSTEM_PNR_LOCATOR}</b>
                <br />
              </small>
              <small>
                EMAIL : <b>{email.toUpperCase()}</b>
              </small>
            </CardTitle>
          ) : (
            <CardTitle style={{ background: "#EAEFF5", padding: "1px" }}>
              {" "}
              {props.t("PASSENGER DETAILS")}{" "}
              <small>
                {" "}
                REFERENCE :{" "}
                <b>{parsedItem.AMD_AirRecordHeader.SYSTEM_PNR_LOCATOR}</b>,
                EMAIL :{" "}
              </small>
              <small>
                <b>{email.toUpperCase()}</b>
              </small>
            </CardTitle>
          )}

          <Table responsive size="sm" style={{ textAlign: "left" }}>
            <tbody>
              <tr>
                <th scope="col">
                  <small>
                    <b> {props.t("TITLE")}</b>
                  </small>
                </th>
                <th scope="col">
                  <small>
                    <b> {props.t("NAME")}</b>
                  </small>
                </th>
                <th scope="col">
                  <small>
                    <b> {props.t("MOBILE")}</b>
                  </small>
                </th>
              </tr>

              {map(parsedItem.I_Name, (passenger, index) => (
                <tr key={index}>
                  <td>
                    <small> {passenger.PASSENGER_NAME_TITLE}</small>
                  </td>
                  <td>
                    <small>
                      {" "}
                      {passenger.PASSENGER_FIRST_NAME}{" "}
                      {passenger.PASSENGER_LAST_NAME}
                    </small>
                  </td>
                  <td>
                    <small>{passenger.PHONE_FIELD_DATA}</small>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {refund === "REFUNDABLE" ? (
            <>
              <CardTitle style={{ background: "#EAEFF5", padding: "1px" }}>
                {" "}
                {props.t("REFUNDABLE STATUS")}{" "}
              </CardTitle>
              <Table responsive size="sm" style={{ textAlign: "left" }}>
                <tbody>
                  <tr>
                    <td>
                      <small className="mx-2 my-auto color-active">
                        Refundable
                      </small>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </>
          ) : (
            <>
              <CardTitle style={{ background: "#EAEFF5", padding: "1px" }}>
                {" "}
                {props.t("REFUNDABLE STATUS")}{" "}
              </CardTitle>
              <Table responsive size="sm" style={{ textAlign: "left" }}>
                <tbody>
                  <tr>
                    <td>
                      <small>
                        <img
                          src={noRefundLogo}
                          width={"60px"}
                          height={"30px"}
                          alt="refund logo"
                          className="mx-2"
                        />
                      </small>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}

          <SellcoItems remarks={remarks} />
        </Col>
      )}
    </>
  )
}

SellingPlatform.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SellingPlatform))
